<template>
    <div class="main" v-if="getIsAppReady">
        <!-- LOADER -->
        <div v-if="loader" class="c-loader">
            <svg class="c-loader--ico" width="100" height="100" viewBox="0 0 44 44">
                <g fill="none" fill-rule="evenodd" stroke-width="2">
                    <circle cx="22" cy="22" r="1">
                        <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/>
                        <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/>
                    </circle>
                    <circle cx="22" cy="22" r="1">
                        <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/>
                        <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/>
                    </circle>
                </g>
            </svg>
        </div>
        <div ref="progressbar" class="progressbarblack"></div>
        <!-- ALERT MESSAGE -->
        <div ref="alert" class="alert text-center" :class="alertClass" role="alert">
            <p class="text--xl fw--bold fc--white">{{this.alertMessage ? translate(this.alertMessage) :''}}</p>
        </div>
        <div class="w-100 d-block">
            <!-- SIDEBAR -->
            <div id="js--menu-mobile" class="sidebar" style="background-color:white">
                <img @click="homeRedirect" :src="getLogo" class="d-block w-50 mx-auto py-2 h-auto cursor-pointer">
                <router-link class="link" v-for="(route, index) in routes" v-if="route.props && route.props.sidebar && route.props.sidebar.show"
                :class="isSideLinkDisabled(index) ? 'disabled' : null"
                 :key="route.path" :to="route.path">
                 <!-- :class="isSideLinkDisabled(index) ? 'disabled' : null"  -->
                <!--    <div class="svg-box" v-html="route.props.icon">
                </div> -->
                    <span class="w-25 text-end text--lg pe-3 fc--black fw--bold">{{(index < 10) ? '0' : null}}{{index}}</span>
                    <div class="w-75">
                        <p class="text--md text-uppercase fc--black mb-0">{{translate(route.props.sidebar.title)}}</p>
                        <p class="text--xs fw--light fc--black">{{translate(route.props.sidebar.subtitle)}}</p>
                    </div>
                </router-link>
            </div>
            <!-- CONTENT -->
            <div class="content">
                <transition name="fade" mode="out-in">
                    <router-view :key="$route.fullPath" />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {mapGetters} from 'vuex'
    export default {
        name: 'Main',
        data: function() {
            return {
                routes: this.$router.options.routes,
                alertMessage: null,
                alertClass:'alert-success',
                loader:false
            }
        },
        computed: {
            ...mapGetters([
                'getIsAppReady',
                'getLogo'
            ])
        },
        methods: {
            homeRedirect(){
                window.location.href = "/"
            },
            animateAlert(){
                this.$gsap.to(this.$refs.alert,{translateY:'0%'});
                setTimeout(() => {
                    this.$gsap.to(this.$refs.alert,{translateY:'100%'})
                }, 4000);
            },
            isSideLinkDisabled(index){
                var result = this.$router.getRoutes().findIndex(obj => {
                  return obj.name === this.$route.name
                })
                if (index <= (result+1)) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        mounted: function() {
            if(localStorage.getItem('build-spa-lang')){
                axios.defaults.headers.common['x-tenant-language'] = localStorage.getItem('build-spa-lang')
            }

            this.$store.dispatch('fetchTenantInformation');

            this.$eventBus.$on('SET-LOADER', (payload) => {
                this.loader = payload;
            });
            this.$eventBus.$on('ALERT-SUCCESS', (msg) => {
                this.alertClass = 'alert-success';
                this.alertMessage = msg;
                this.animateAlert();
            });
            this.$eventBus.$on('ALERT-ERROR', (msg) => {
                console.log(msg);
                this.alertClass = 'alert-danger';
                this.alertMessage = msg;
                this.animateAlert();
            });
        },
        watch: {
            '$route': {
                handler: function(oldVal, newVal) {
                    let currentIndex = this.$router.options.routes.findIndex(route => route.path == this.$route.path);
                    let routesLenght = this.$router.options.routes.length;
                    let length = 0;
                    if (currentIndex != 0) {
                        length = (( 100 * currentIndex) / (this.$router.options.routes.length)) +'%';
                    }
                    this.$gsap.to(this.$refs.progressbar,{width:length});

                    this.$eventBus.$emit('HIDE-MOBILE-MENU');

                },
                deep: true
            }
        }
    }
</script>
