<template>
    <BaseLayout :title="translate($attrs.pageTitle)" :subtitle="translate($attrs.pageSubtitle)" :steps="$attrs.steps" :variant="'default'" :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-center">
                    <div class="w-100 row" style="justify-content: center">
                        <div @click="setActiveProduct(pt)" v-for="pt in products" v-if="products.length != 4" class="d-block col-lg-4 h-auto text-center mb-1 mb-lg-0">
                            <div class="sm:px-0 px-2">
                                <img v-if="!active_product.id || pt.id != active_product.id" :src="require('@/assets/images/png/leveler-' + pt.id + '-off.png')" class="d-block w-75 sm:w-100 mx-auto h-auto px-xl-5 px-lg-2 px-4" style="cursor:pointer">
                                <img v-if="pt.id == active_product.id" :src="require('@/assets/images/png/leveler-' + pt.id + '-on.png')" class="d-block w-75 sm:w-100 mx-auto h-auto px-xl-5 px-lg-2 px-4" style="cursor:pointer">
                                <!-- <img :src="require('@/assets/images/png/leveler-' + pt.id + '.png')" class="d-block w-75 sm:w-100 mx-auto h-auto px-lg-5"> -->
                                <p class="text--xl fw--bold text-uppercase fc--black mt-lg-3 mb-lg-3 mt-3 mb-0">{{pt.name}}</p>
                            </div>
                        </div>
                        <div @click="setActiveProduct(pt)" v-for="pt in products" v-if="products.length == 4" class="d-block col-lg-3 h-auto text-center mb-1 mb-lg-0">
                            <div class="sm:px-0 px-2">
                                <img v-if="!active_product.id || pt.id != active_product.id" :src="require('@/assets/images/png/leveler-' + pt.id + '-off.png')" class="d-block w-100 sm:w-100 mx-auto h-auto px-xl-5 px-lg-2 px-4" style="cursor:pointer">
                                <img v-if="pt.id == active_product.id" :src="require('@/assets/images/png/leveler-' + pt.id + '-on.png')" class="d-block w-100 sm:w-100 mx-auto h-auto px-xl-5 px-lg-2 px-4" style="cursor:pointer">
                                <!-- <img :src="require('@/assets/images/png/leveler-' + pt.id + '.png')" class="d-block w-75 sm:w-100 mx-auto h-auto px-lg-5"> -->
                                <p class="text--xl fw--bold text-uppercase fc--black mt-lg-3 mb-lg-3 mt-3 mb-0">{{pt.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios'
    import {mapState, mapGetters} from 'vuex'
    export default {
        name: 'Levelerz',
        beforeMount: function() {
            this.$eventBus.$emit('SET-LOADER',true)
        },
        mounted: function(){
            // SE NON HO SELECTED
            // if (!this.$store.state.active_product.id) {
            //     this.setActiveProduct(this.products[0])
            // }
            this.$eventBus.$emit('SET-LOADER',false)
        },
        computed: {
            ...mapGetters([
                'getCurrentLang'
            ]),
            ...mapState({
                products: state => state.products,
                active_product: state => state.active_product
            })
        },
        methods: {
            setActiveProduct(pt){
                this.$store.commit('updateState', {
                    key: 'active_product',
                    value: pt
                });
                this.$router.push({
                    name: 'sizes',
                })
            },
            prevStep(){
                return null
            },
            nextStep(){
                if (!this.$store.state.active_product.id) {
                    return null
                } else {
                    return 'sizes'
                }
            }
        }
    }
</script>
