<template>
    <BaseLayout :title="translate($attrs.pageTitle)" :subtitle="translate($attrs.pageSubtitle)" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-start">
                    <div class="w-100 row">
                        <div class="col-lg-12">
                          <div class="row">
                            <div class="col-xl-4 col-lg-5 col-12">
                              <img :src="require('@/assets/images/svg/fuga.svg')" class="d-block w-100 sm:w-100 mx-auto h-auto px-lg-0">
                            </div>
                            <div class="col-xl-8 col-lg-7 col-12">
                              <p class="text--xl text-uppercase d-block fc--blue fw--bold mt-4">{{translate('decking.system.tile')}}</p>
                              <div class="row">
                                <div class="col-lg-6 col-6">
                                  <div class="mb-3 d-block">
                                      <label class="text--md mb-2 fw--bold text-uppercase" for="">{{translate(tile_sizes[0].label)}}</label>
                                      <div class="d-flex">
                                          <input @keypress="isNumber($event)" type="number" min="100" class="c-input text--md d-block col-12 col-lg-5" v-model="tile_sizes[0].value">
                                          <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                              <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="mb-3 d-block">
                                      <label class="text--md mb-2 fw--bold text-uppercase" for="">{{translate(tile_sizes[1].label)}}</label>
                                      <div class="d-flex">
                                          <input @keypress="isNumber($event)" type="number" min="100" class="c-input text--md d-block col-12 col-lg-5" v-model="tile_sizes[1].value">
                                          <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                              <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                                <div class="col-lg-6 col-6">
                                  <div class="mb-3 d-block">
                                      <label class="text--md mb-2 fw--bold text-uppercase" for="">{{translate(tile_sizes[2].label)}}</label>
                                      <div class="d-flex">
                                          <select class="text--md d-block c-input col-12 col-lg-5" v-model="tile_sizes[2].value">
                                              <option v-for="(leak, index) in leaks" :value="leak" v-bind:selected="$index === 4 ? 'selected' : ''">{{leak}}</option>
                                          </select>
                                          <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                              <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="mb-3 d-block">
                                      <label class="text--md mb-2 fw--bold text-uppercase" for="">{{translate(tile_sizes[3].label)}}</label>
                                      <div class="d-flex">
                                        <select class="text--md d-block c-input col-12 col-lg-5" v-model="tile_sizes[3].value">
                                            <option v-for="index in htilemax" v-if="index > 2" :value="index" v-bind:selected="$index === 0 ? 'selected' : ''">{{index}}</option>
                                        </select>
                                        <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                            <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                        </div>
                                          <!-- <input @keypress="isNumber($event, max = htilemax)" type="number" :min="htilemin" :max="htilemax" class="c-input text--md d-block col-12 col-lg-5" v-model="tile_sizes[3].value">
                                          <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                              <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                          </div> -->
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="row">
                            <div class="col-xl-4 col-lg-5 col-12 py-lg-4 py-0">
                              <img :src="require('@/assets/images/svg/area.svg')" class="d-block w-75 sm:w-100 mx-auto h-auto px-lg-0">
                              <div class="tile-letters-box--letter text--md" style="top:-18%; left:45%; position:relative !important;">A</div>
                              <div class="tile-letters-box--letter text--md" style="top:-58%; left:82%; position:relative !important;">B</div>
                            </div>
                            <div class="col-xl-8 col-lg-7 col-12">
                              <div class="row">
                                <p class="text--xl text-uppercase d-block fc--blue fw--bold mt-lg-4 mt-0 col-12">{{translate('decking.system.area')}}</p>
                                <div class="mb-3 d-block col-lg-6 col-6" v-for="(size, index) in area_sizes">
                                    <label class="text--md mb-2 fw--bold text-uppercase" for="">{{translate(size.label)}}</label>
                                    <div class="d-flex">
                                        <input @keypress="isNumber($event)" type="number" min="1" class="c-input text--md d-block col-12 col-lg-5" v-model="size.value">
                                        <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                            <p class="text--sm fw--bold fc--blue px-1">m</p>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios'
    import {mapState, mapGetters} from 'vuex'
    import {
        required,
        minLength
    } from 'vuelidate/lib/validators'
    export default {
        name: 'Sizes',
        methods: {
            prevStep(){
                return 'levelers'
            },
            nextStep(){
                return 'orientation'
            }
        },
        beforeMount: function(){
            this.leaks = this.getActiveProduct.joint;
            this.htilemin = this.getActiveProduct.htilemin;
            this.htilemax = this.getActiveProduct.htilemax;
            this.tile_sizes[2].value = this.leaks[0];
        },
        data: function() {
            return {
                leaks: [],
                htilemin:0,
                htilemax:0,
            }
        },
        computed: {
          ...mapGetters([
            'getActiveProduct'
          ]),
            ...mapState({
                tile_sizes: state => state.tile_sizes,
                area_sizes: state => state.area_sizes
            })
        }
    }
</script>
