<template>
    <div class="home-screen w-100 vh-100 d-flex flex-column">
        <div class="container home-screen--textbox">
            <div class="row w-100">
                <div class="col-lg-10 text-lg-start text-center">
                    <p class="fc--black fw--bold text--xxl">{{getBuilderName}}</p>
                    <p class="fc--black fw--bold text--xxl mb-4">{{getBuilderSlogan}}</p>
                    <p class="text--md fc--black fw--light mb-5" v-html="getBuilderAbstract"></p>
                    <button @click="nextStep()" class="btn text--md text-uppercase btn-personalized-outline" type="button">{{translate('decking.intro.cta')}}</button>
                </div>
                <div class="col-lg-2 mt-4 mt-md-0">
                    <p class="text--sm mb-2 text-uppercase text-center text-md-start" v-if="getAvailableLanguage.length > 1">Select language</p>
                    <p class="fc--black fw--bold text--xxl" v-if="getAvailableLanguage.length > 0">
                        <div class="d-flex justify-content-md-start justify-content-center" v-if="getAvailableLanguage.length > 0">
                            <div v-for="lang in getAvailableLanguage" class="text-uppercase text-center blocklangcontainer">
                                <a @click="switchLang(lang)" class="blocklang fw--bold">
                                    {{lang}}
                                </a>
                            </div>
                        </div>
                    </p>
                </div>
            </div>
        </div>
        <div class="home-screen--imgbox container-fluid" :style='{backgroundImage:"url("+ getFeaturedImage +")"}'></div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'HomeScreen',
        data: function() {
            return {
            }
        },
        computed:{
            ...mapGetters([
                'getBuilderName',
                'getAvailableLanguage',
                'getBuilderSlogan',
                'getBuilderAbstract',
                'getFeaturedImage'
            ])
        },
        methods: {
            switchLang(lang){
              localStorage.setItem('build-spa-lang', lang)
              setTimeout(() => location.reload())
            },
            nextStep(){
                this.$router.push({
                    name: 'levelers',
                })
            }
        }
    }
</script>
