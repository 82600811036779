<template>
    <BaseLayout :title="translate($attrs.pageTitle)" :subtitle="translate($attrs.pageSubtitle)" :steps="$attrs.steps" :variant="'default'" :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-center">
                    <div class="w-100 row">
                        <div @click="setActiveOrientation(orientation)" v-for="orientation in orientations" class="d-block col-lg-6 h-auto text-center mb-lg-5 mb-0 mb-lg-0">
                            <div class="sm:px-0 px-2">
                                <img v-if="!active_orientation.id || orientation.id != active_orientation.id" :src="require('@/assets/images/svg/orientation-' + orientation.value + '-off.svg')" class="d-block w-75 sm:w-100 mx-auto h-auto px-xl-5 px-lg-2" style="cursor:pointer">
                                <img v-if="orientation.id == active_orientation.id" :src="require('@/assets/images/svg/orientation-' + orientation.value + '-on.svg')" class="d-block w-75 sm:w-100 mx-auto h-auto px-xl-5 px-lg-2" style="cursor:pointer">
                                <p class="text--xl fw--bold text-uppercase fc--black mt-2 mb-1">{{translate(orientation.name)}}</p>
                                <p class="text--md fc--black">{{translate(orientation.description)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios';
    import {mapState} from 'vuex';
    export default {
        name: 'Orientation',
        beforeMount: function() {
            // this.$eventBus.$emit('SET-LOADER',true)
        },
        mounted: function(){
            // SE NON HO SELECTED
            // if (!this.active_orientation.id) {
            //     this.setActiveOrientation(this.orientations[0])
            // }
            // this.$eventBus.$emit('SET-LOADER',false)
        },
        computed: {
            ...mapState({
                orientations: state => state.orientations,
                active_orientation: state => state.active_orientation
            }),
            // getBuildSpaLang: () => localStorage.getItem('build-spa-lang')
        },
        methods: {
            setActiveOrientation(orientation){
                this.$store.commit('updateState', {
                    key: 'active_orientation',
                    value: orientation
                });
                this.$router.push({
                    name: 'laying',
                })
            },
            prevStep(){
                return 'sizes'
            },
            nextStep(){
                if (!this.active_orientation.id) {
                    return null
                } else {
                    return 'laying'
                }
            }
        }
    }
</script>
