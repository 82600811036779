import Vue from 'vue'
import Vuex from 'vuex'

import _ from 'lodash'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loaded: false,
    lang: null,
    payload: [],
    company: [],
    products: [],
    active_product: {},
    tile_sizes: [{
      label: 'decking.system.sidea',
      value: 500
    }, {
      label: 'decking.system.sideb',
      value: 400
    }, {
      label: 'decking.system.fuga',
      value: 3
    }, {
      label: 'decking.system.altezzamattonella',
      value: 11
    }],
    area_sizes: [{
      label: 'decking.system.areasidea',
      value: 8
    }, {
      label: 'decking.system.areasideb',
      value: 6
    }],
    orientations: [{
      id: 1,
      name: 'decking.orientation.horizzontal',
      description: 'decking.orientation.horizzontaltext',
      image: 'https://via.placeholder.com/150x150',
      value: 'horizontal'
    }, {
      id: 2,
      name: 'decking.orientation.vertical',
      description: 'decking.orientation.verticaltext',
      image: 'https://via.placeholder.com/150x150',
      value: 'vertical'
    }],
    active_orientation: {},

    //LAYING
    layings: [{
      id: 1,
      name: 'decking.layout.standard',
      description: 'decking.layout.standardtext',
      image: 'https://via.placeholder.com/150x150',
      value: 'classica',
      active: 'true'
    }, {
      id: 3,
      name: 'decking.layout.parquet',
      description: 'decking.layout.parquettext',
      image: 'https://via.placeholder.com/150x150',
      value: 'parquet',
      active: 'true'
    }, {
      id: 4,
      name: 'decking.layout.diagonale',
      description: 'decking.layout.diagonaletext',
      image: 'https://via.placeholder.com/150x150',
      value: 'diagonale',
      active: 'false'
    }],
    active_laying: {},
    summary: {},
    system: {},
    packs: {}
  },
  mutations: {
    updateState(state, payload) {
      state.[payload.key] = payload.value;
    },
    UPDATE_LANGUAGE(state, lang) {
      state.lang = lang
      localStorage.setItem('build-spa-lang', lang)
    }
  },
  actions: {
    fetchTenantInformation: ({
      commit,
      state
    }) => {
      axios.get('/api/v1/me').then((res) => {
        const iso = res.data.data.lang.iso

        // Set Company
        commit('updateState', {
          key: 'company',
          value: res.data.data
        })

        // Set current language
        var firstAvailableLang = localStorage.getItem('build-spa-lang')
        if (!firstAvailableLang || !_.includes(iso, firstAvailableLang)) {
          firstAvailableLang = _.first(iso)
        }
        commit('UPDATE_LANGUAGE', firstAvailableLang)

        // Set Products
        commit('updateState', {
          key: 'products',
          value: res.data.data.products
        })

        // Set builder name
        document.title = state.company.builder_name[state.lang]

        // Load application
        commit('updateState', {
          key: 'loaded',
          value: true
        })
      }).catch((e) => {
        alert("Error!")
        console.log(e)
      })
    }
  },
  getters: {
    getIsAppReady: (state) => state.loaded,
    getCurrentLanguage: (state) => state.lang,
    getAvailableLanguage: (state) => state.company.lang.iso,
    getTranslation: (state) => state.company.lang.json,
    getBuilderName: (state) => state.company.builder_name[state.lang],
    getBuilderSlogan: (state) => state.company.builder_slogan[state.lang],
    getBuilderAbstract: (state) => state.company.builder_abstract[state.lang],
    getIsTentantMailerConfigIsValid: (state) => state.company.smtp,
    getCurrentLang: (state) => state.lang,
    getActiveProduct: (state) => state.active_product,
    getPayload: (state) => {
      let payload = {};

      // Set product id
      payload.product_id = state.active_product.id

      // Convert width e depth in mm
      payload.width = (state.area_sizes[0].value * 1000);
      payload.depth = (state.area_sizes[1].value * 1000);

      // leak
      payload.leak = state.tile_sizes[2].value;

      // tileheight
      payload.tileheight = state.tile_sizes[3].value;

      // Set strategy
      payload.strategy = state.active_laying.value;

      /**
       * 1 = LINEARE
       * 2 = SPACCAMATTONE
       * 3 = PARQUET
       * 4 = DIAGONALE
       */

      if (state.active_laying.id === 1) {
        payload.tiles = [{
          width: state.tile_sizes[0].value,
          depth: state.tile_sizes[1].value,
          direction: state.active_orientation.value
        }]
      } else if (state.active_laying.id === 2) {
        payload.tiles = [{
          width: state.tile_sizes[0].value,
          depth: state.tile_sizes[1].value,
          direction: state.active_orientation.value
        }, {
          width: state.tile_sizes[0].value / 2,
          depth: state.tile_sizes[1].value,
          direction: state.active_orientation.value
        }];
      } else if (state.active_laying.id === 3) {
        payload.tiles = [{
          width: state.tile_sizes[0].value / 4 * 1,
          depth: state.tile_sizes[1].value,
          direction: state.active_orientation.value
        }, {
          width: state.tile_sizes[0].value / 4 * 2,
          depth: state.tile_sizes[1].value,
          direction: state.active_orientation.value
        }, {
          width: state.tile_sizes[0].value / 4 * 3,
          depth: state.tile_sizes[1].value,
          direction: state.active_orientation.value
        }, {
          width: state.tile_sizes[0].value,
          depth: state.tile_sizes[1].value,
          direction: state.active_orientation.value
        }];
      } else if (state.active_laying.id === 4) {
        payload.width = payload.width / 2;
        payload.depth = payload.depth / 2;

        var sideA = Math.sqrt(Math.pow(payload.width, 2) + Math.pow(payload.width, 2));
        var sideB = Math.sqrt(Math.pow(payload.depth, 2) + Math.pow(payload.depth, 2));
        var widthDepth = Math.ceil(sideA + sideB)

        payload.width = widthDepth
        payload.depth = widthDepth

        payload.tiles = [{
          width: state.tile_sizes[0].value,
          depth: state.tile_sizes[1].value,
          direction: state.active_orientation.value
        }]
      }

      return payload
    },
    getLogo: (state) => {
      if (state.company.logo) {
        return state.company.logo
      }
      return require('@/assets/images/logo.png')
    },
    getFeaturedImage: (state) => {
      if (state.company.featured) {
        return state.company.featured
      }
      return require('@/assets/images/home-bg.jpg')
    }
  }
})
