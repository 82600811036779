import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeScreen from './../components/HomeScreen.vue';
import Levelers from './../components/Levelers.vue';
import Sizes from './../components/Sizes.vue';
import Orientation from './../components/Orientation.vue';
import Laying from './../components/Laying.vue';
import System from './../components/System.vue';
Vue.use(VueRouter)

const routes = [{
  name: 'home-screen',
  path: '/',
  component: HomeScreen,
  props: {
    sidebar: {
      show: false,
    }
  }
}, {
  name: 'levelers',
  path: '/levelers',
  component: Levelers,
  props: {
    sidebar: {
      show: true,
      title: 'steps.levelers.title',
      subtitle: 'steps.levelers.subtitle'
    },
    pageTitle: 'steps.levelers.title',
    pageSubtitle: 'steps.levelers.subtitle'
  }
}, {
  name: 'sizes',
  path: '/sizes',
  component: Sizes,
  props: {
    sidebar: {
      show: true,
      title: 'steps.sizes.title',
      subtitle: 'steps.sizes.subtitle'
    },
    pageTitle: 'steps.sizes.title',
    pageSubtitle: 'steps.sizes.subtitle'
  }
}, {
  name: 'orientation',
  path: '/orientation',
  component: Orientation,
  props: {
    sidebar: {
      show: true,
      title: 'steps.orientation.title',
      subtitle: 'steps.orientation.subtitle'
    },
    pageTitle: 'steps.orientation.title',
    pageSubtitle: 'steps.orientation.subtitle'
  }
}, {
  name: 'laying',
  path: '/laying',
  component: Laying,
  props: {
    sidebar: {
      show: true,
      title: 'steps.laying.title',
      subtitle: 'steps.laying.subtitle'
    },
    pageTitle: 'steps.laying.title',
    pageSubtitle: 'steps.laying.subtitle'
  }
}, {
  name: 'system',
  path: '/system',
  component: System,
  props: {
    sidebar: {
      show: true,
      title: 'steps.system.title',
      subtitle: 'steps.system.subtitle'
    },
    pageTitle: 'steps.system.title',
    pageSubtitle: 'steps.system.subtitle'
  }
}];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
