import "@/assets/css/app.scss";

import Vue from 'vue';
import Vuex from 'vuex';
import gsap from "gsap";
import moment from 'moment';
import Lottie from 'lottie-web';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import vSelect from 'vue-select'
import axios from 'axios';
import CountryFlag from 'vue-country-flag'

//SECTIONS/LAYOUTS
import App from './App.vue';
import BaseLayout from './components/base/BaseLayout.vue';

import router from './router'
import store from './store'

import {
  translate
} from './VueTranslation/Translation';
import _ from 'lodash'

Vue.component('v-select', vSelect);
Vue.component('BaseLayout', BaseLayout);

Vue.component('country-flag', CountryFlag)

Vue.prototype.moment = moment;

Vue.mixin({
  created: function() {
    this.$gsap = gsap;
  },
  methods: {
    translate(key, rep = {}) {
       var translations = this.$store.getters.getTranslation;
       var currentLang = this.$store.getters.getCurrentLanguage
       return translate(key, translations, currentLang, rep)
    },
    isNumber: function(evt, max = null) {
      evt = (evt) ? evt : window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode != 43 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      }
      // check x max
      setTimeout(function () {
          if (max && (evt.target.value >= max)) {
              evt.target.value = max;
          }
      }, 10);

    }
  }
});

Vue.use(Vuelidate);
Vue.use(Vuex);

Vue.prototype.$eventBus = new Vue();

const vm = new Vue({
  render: h => h(App),
  router,
  store
});

vm.$mount('#app')

export { vm }
