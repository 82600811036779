<template>
    <BaseLayout :title="translate($attrs.pageTitle)" :subtitle="translate($attrs.pageSubtitle)" :steps="$attrs.steps" :variant="'default'" :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-center">
                    <div class="w-100 row align-items-center">
                        <div class="col-lg-2"></div>
                        <div @click="setActiveLaying(laying)" v-for="laying in layings" class="d-block col-lg-4 h-auto text-center mb-lg-0 mb-1 col-12" v-if="laying.active == 'true'">
                            <div class="sm:px-0 px-2">
                                <img v-if="!active_laying.id || laying.id != active_laying.id" :src="require('@/assets/images/svg/laying-' + laying.value + '-off.svg')" class="d-block w-100 sm:w-100 mx-auto h-auto px-xl-5 px-lg-2 px-5" style="cursor:pointer;">
                                <img v-if="laying.id == active_laying.id" :src="require('@/assets/images/svg/laying-' + laying.value + '-on.svg')" class="d-block w-100 sm:w-100 mx-auto h-auto px-xl-5 px-lg-2 px-5" style="cursor:pointer">
                                <p class="text--xl fw--bold text-uppercase fc--black mt-3 mb-lg-3 mb-1">{{translate(laying.name)}}</p>
                                <p class="text--md fc--black">{{translate(laying.description)}}</p>
                            </div>
                        </div>
                        <!-- <div v-for="laying in layings" class="d-block col-lg-3 h-auto text-center mb-5 mb-lg-0" style="opacity: 0.5;" v-if="laying.active == 'false'">
                            <div class="sm:px-0 px-2">
                                <img v-if="!active_laying.id || laying.id != active_laying.id" :src="require('@/assets/images/svg/laying-' + laying.value + '-off.svg')" class="d-block w-100 sm:w-100 mx-auto h-auto px-lg-1">
                                <img v-if="laying.id == active_laying.id" :src="require('@/assets/images/svg/laying-' + laying.value + '-off.svg')" class="d-block w-100 sm:w-100 mx-auto h-auto px-lg-1" style="cursor:pointer">
                                <p class="text--xl fw--bold text-uppercase fc--black mt-3 mb-3">{{translate(laying.name)}}</p>
                                <p class="text--md fc--black">{{translate(laying.description)}}</p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios';
    import {mapState} from 'vuex';
    import { mapActions } from 'vuex';

    export default {
        name: 'Laying',
        mounted: function(){
            // SE NON HO SELECTED
            // if (!this.active_laying.id) {
            //     this.setActiveLaying(this.layings[0])
            // }
        },
        computed: {
            ...mapState({
                layings: state => state.layings,
                active_laying: state => state.active_laying
            }),
        },
        methods: {
            setActiveLaying(laying){
                this.$store.commit('updateState', {
                    key: 'active_laying',
                    value: laying
                });
                this.$router.push({
                    name: 'system',
                })
            },
            prevStep(){
                return 'orientation'
            },
            nextStep(){
                if (!this.active_laying.id) {
                    return null
                } else {
                    return 'system'
                }
            }
        }
    }
</script>
