<template>
    <div class="page" :class="variant">
        <!-- SIDEBAR -->
        <div class="header-mobile pe-5">
            <img @click="homeRedirect" :src="getLogo" class="d-block w-50 py-2 px-3 me-auto h-auto  cursor-pointer">
        </div>
        <div class="page-header">
            <div class="row w-100">
                <div class="col-12">
                    <p v-if="title" class="text--xl text-uppercase d-block fc--black fw--bold text-center mt-2">
                        {{title}}
                    </p>
                    <p v-if="subtitle" class="text--sm d-block fc--black text-center" v-html="subtitle">
                    </p>
                </div>
            </div>
        </div>
        <div class="page-container row">
            <!-- contenuto pagina -->
            <slot></slot>
        </div>
        <div class="page-footer">
            <div class="row w-100 px-3 g-0">
                <div class="col-12 d-flex justify-content-lg-end justify-content-between">
                    <button @click="goToPrev()" :disabled="!prev" class="btn text--md text-uppercase px-4 btn-outline-primary btn-personalized-outline" type="button">{{translate(prevText)}}</button>
                    <button v-if="(next == 'quotation' && getIsTentantMailerConfigIsValid) || next != 'quotation'" @click="goToNext()" :disabled="!next" class="btn text--md text-uppercase px-4 btn-primary btn-personalized-primary ms-2" type="button">{{translate(nextText)}}</button>
                </div>
            </div>
        </div>

        <div class="menu-mobile">
            <div class="row w-100 p-3 g-0">
                <div class="col-12 d-flex justify-content-lg-end justify-content-between">
                    <button @click="goToPrev()" :disabled="!prev" class="btn text--md text-uppercase px-4 btn-outline-primary btn-personalized-outline" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.69 59.72"><path d="M1.21,32l27.7,27.69,4.18-4.18L10.44,32.9H58.69V27H10.44L33.09,4.34,28.91.16,1.21,27.85A3,3,0,0,0,1.21,32Z"/></svg>
                    </button>
                    <div class="d-flex align-items-center w-100 px-3">
                        <div id="js--hamburger" class="hamburger">

                        </div>
                        <p v-if="title" class="ps-3 text--xxs text-uppercase d-block fc--black fw--bold">
                            {{title}}
                        </p>
                    </div>
                    <button @click="goToNext()" v-if="$route.name != 'system'" :disabled="!next" class="btn text--md text-uppercase px-4 btn-outline-primary btn-personalized-outline ms-2" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.69 59.72"><path d="M57.82,27.85,30.13.16,26,4.34,48.59,27H.35V32.9H48.59L26,55.54l4.18,4.18L57.82,32A3,3,0,0,0,57.82,27.85Z"/></svg>
                    </button>
                    <button @click="goToNext()" v-if="$route.name == 'system'" :disabled="!next" class="btn text--md text-uppercase px-4 ms-2" type="button">
                        <img src="/images/svg/download-pdf.svg" class="d-block w-100" style="height:40px">
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import Lottie from 'lottie-web';
    import gsap from "gsap";
    import {mapGetters} from 'vuex'
    export default {
        name: 'BaseLayout',
        props: {
            prev: null,
            next: null,
            title: String,
            subtitle: String,
            variant: {
                type: String,
                default: 'default'
            },
        },
        data: function(){
            return {
                nextText:'decking.base.continua',
                prevText:'decking.base.precedente',
            }
        },
        mounted: function(){
            if (this.next == 'pdf') {
                this.nextText = 'decking.base.pdf';
            } else {
                this.nextText = 'decking.base.successivo';
            }

            let hamburger = document.querySelector('#js--hamburger');
            let menuMobile = document.querySelector('#js--menu-mobile');

             hamburger.mobileMenuTl = gsap.timeline({
              paused: true,
              reversed: true
            })
            .to(menuMobile, .001, {display:'block',immediateRender:false})
            .fromTo(menuMobile, .5, {autoAlpha:0}, {autoAlpha:1,immediateRender:false});

            hamburger.JSON_hambuger_animation = Lottie.loadAnimation({
              container: hamburger, // Required
              animationData: require('@/assets/images/hamburger.json'),
              renderer: 'svg', // Required
              loop: false, // Optional
              autoplay: false, // Optional
              filterSize: {
                  width: '100%',
                  height: '100%'
                }
            });
            hamburger.JSON_hambuger_animation.setSpeed(2);

            hamburger.addEventListener('click', function () {
              if (hamburger.mobileMenuTl.reversed()) {
                hamburger.JSON_hambuger_animation.setDirection(1);
                hamburger.JSON_hambuger_animation.play();
                setTimeout(() => {
                  hamburger.mobileMenuTl.play();
                }, 500);
              } else {
                hamburger.mobileMenuTl.reverse().eventCallback('onReverseComplete', function () {
                  hamburger.JSON_hambuger_animation.setDirection(-1);
                  hamburger.JSON_hambuger_animation.play();
                });
              }
            })

            this.$eventBus.$on('HIDE-MOBILE-MENU', () => {
                  hamburger.mobileMenuTl.reverse().eventCallback('onReverseComplete', function () {
                    hamburger.JSON_hambuger_animation.setDirection(-1);
                    hamburger.JSON_hambuger_animation.play();
                  });
            });

        },
        computed: {
            ...mapGetters([
                'getIsTentantMailerConfigIsValid',
                'getLogo'
            ])
        },
        methods: {
            homeRedirect(){
                window.location.href = "/"
            },
            goToPrev(){
                this.$router.push({
                    name: this.prev,
                })
            },
            goToNext(){
              if (this.next == 'pdf') {
                let toSend = {};
                toSend.product = this.$store.state.active_product.id;
                toSend.laying = this.$store.state.active_laying.value;
                toSend.tile_sizes = this.$store.state.tile_sizes;
                toSend.area = this.$store.state.area_sizes;
                toSend.tile_orientation = this.$store.state.active_orientation.value;
                toSend.render = this.$store.state.summary;

                this.$eventBus.$emit('SET-LOADER',true);
                axios.post('/api/v1/pdf/create', toSend).then(response => {
                    this.$eventBus.$emit('SET-LOADER',false);
                    // TODO: verificare
                    window.location.href =response.data.link;
                }).catch(e => {
                    this.$eventBus.$emit('SET-LOADER',false);
                    this.$eventBus.$emit('ALERT-ERROR','decking.system.pdfcreateerror');
                });
              } else {
                if(this.$store.state.active_product.id == 1) {
                  if(this.$store.state.tile_sizes[0].value<100 || this.$store.state.tile_sizes[1].value < 100 || this.$store.state.tile_sizes[3].value < 3 || this.$store.state.tile_sizes[3].value > 12) {
                    this.$eventBus.$emit('ALERT-ERROR','decking.errors.tilesize12');
                  } else {
                    this.$router.push({
                        name: this.next,
                    })
                  }
                } else {
                  if(this.$store.state.tile_sizes[0].value<100 || this.$store.state.tile_sizes[1].value < 100 || this.$store.state.tile_sizes[3].value < 3 || this.$store.state.tile_sizes[3].value > 22) {
                    this.$eventBus.$emit('ALERT-ERROR','decking.errors.tilesize22');
                  } else {
                    this.$router.push({
                        name: this.next,
                    })
                  }
                }
               
              }

            }

      },
    }
</script>
