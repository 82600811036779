<template>
    <BaseLayout :title="translate($attrs.pageTitle)" :subtitle="translate($attrs.pageSubtitle)" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row scroll h-100">
                <div class="col-12">

                    <div v-if="summary" class="row">
                        <div class="col-lg-12 bg-summary text-center order-3 order-md-3 mt-3 mt-lg-5">
                            <div class="row">
                                <!-- <div class="col-12 mt-5">
                                    <p class="text--md text-uppercase fc--black fw--bold mt-4">{{translate('decking.system.ituoidati')}}</p>
                                </div> -->
                                <div v-if="active_product" class="mb-lg-3 mb-5 col-6 col-lg-3">
                                    <!-- <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.product')}}</p>
                                    <img :src="require('@/assets/images/png/leveler-' + active_product.id + '.png')" class="d-block mx-auto" style="height:160px;">
                                    <p class="text--sm mb-1">{{active_product.name}}</p> -->
                                    <!-- <p v-if="summary.support_count" class="text--sm mb-1">{{translate('decking.summary.support_count')}} {{summary.support_count}}</p> -->
                                    <img :src="require('@/assets/images/svg/fuga.svg')" class="d-block mx-auto" />
                                    <p v-if="summary.tile_count" class="text--sm mb-1">{{translate('decking.summary.tile_count')}} {{summary.tile_count}}</p>
                                </div>

                                <div v-if="tile_sizes && area_sizes" class="mb-lg-3 mb-5 col-6 col-lg-3">
                                    <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.tile_size')}}</p>
                                    <div v-for="(size, index) in tile_sizes">
                                        <p class="text--sm mb-1">{{translate(size.label)}}: {{size.value}}mm</p>
                                    </div>
                                    <p class="text--lg mt-4 text-uppercase fw--bold fc--black">{{translate('decking.summary.area_size')}}</p>
                                    <div v-for="(size, index) in area_sizes">
                                        <p class="text--sm mb-1">{{translate(size.label)}}: {{size.value}}m</p>
                                    </div>
                                </div>

                                <div v-if="active_orientation" class="mb-lg-3 mb-5 col-6 col-lg-3">
                                    <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.orientation')}}</p>
                                    <img :src="require('@/assets/images/svg/orientation-' + active_orientation.value + '.svg')" class="d-block mx-auto" style="height:160px;">
                                    <p class="text--sm mb-1">{{translate(active_orientation.name)}}</p>
                                </div>

                                <div v-if="active_laying" class="mb-lg-3 mb-5 col-6 col-lg-3">
                                    <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.laying')}}</p>
                                    <img :src="require('@/assets/images/svg/laying-' + active_laying.value + '.svg')" class="d-block mx-auto" style="height:160px;">
                                    <p class="text--sm mb-1">{{translate(active_laying.name)}}</p>
                                </div>
                            </div>

                        </div>
                        <!-- prodotti -->
                        <div class="col-lg-12 mt-5 mt-lg-0 order-1 order-md-1">
                            <div class="c-table-prod pb-3">
                                <!-- <div class="row g-0 d-md-none">
                                  <div class="col-12"></div>
                                </div>
                                <div class="row g-0 d-none d-lg-flex w-100">
                                    <div class="col-1"></div>
                                    <div class="col-6">
                                        <div class="td">
                                            <p class="text--xs text-uppercase fc--black fw--bold mt-0 mb-3">{{translate('decking.system.product')}}</p>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <div class="td">
                                            <p class="text--xs text-uppercase fc--black fw-bold mt-0 mb-3"><span class="d-block d-md-none">{{translate('decking.system.qtynec')}}: </span>{{translate('decking.system.qtynec')}}</p>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="row g-0 mt-5">
                                    <div class="col-lg-2 col-12"></div>
                                    <div class="col-lg-4 col-12" v-for="(product, index) in system" v-if="product.base < 3">
                                        <div class="row g-0" >
                                            <div class="col-lg-2 offset-lg-0 col-8 offset-2">
                                                <div class="td text-center text-md-start">
                                                    <img v-if="product.image" :src="product.image" class="mb-1 w-75 w-sm-100 mx-auto px-xl-0 px-lg-2" />
                                                </div>
                                            </div>

                                            <div class="col-lg-7 col-12">
                                                <div class="td text-center text-md-start">
                                                    <!-- <p class="text--sm fc--black">{{product.commercial_code}}</p> -->
                                                    <p class="text--lg fc--black fw--bold mt-1">{{product.name}}</p>
                                                    <!-- <p class="text--sm fc--black">{{translate('decking.product.altezzareg')}}: {{product.regolazioneh}}</p> -->
                                                    <!-- <p class="text--sm fc--black">{{translate('decking.product.diambase')}}: {{product.diametrobase}}, {{translate('decking.fuga.title')}}: {{product.fuga}} mm, {{translate('decking.summary.portata')}}: {{product.portata}} kn</p> -->
                                                    <!-- <p class="text--sm fc--black">{{translate('decking.product.diambase')}}: {{product.diametrobase}}</p> -->
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-12">
                                                <div class="td text-center text-md-center">
                                                <p class="text--sm fc--black mt-4" v-if="product.is_required == 1 && ((summary.width * summary.depth) / 1000000) <= 20">
                                                    <span class="d-md-none">{{translate('decking.system.qtynec')}} a: </span>{{summary.support_count}} {{translate('decking.system.umprod')}}</span>
                                                </p>
                                                <p class="text--sm fc--black mt-4" v-if="product.is_required == 1 && ((summary.width * summary.depth) / 1000000) > 20">
                                                    <span class="d-md-none">{{translate('decking.system.qtynec')}} b: </span>{{((20 / ((tile_sizes[0].value*tile_sizes[1].value) / 1000000)) * (summary.support_count / summary.tile_count)).toFixed(0)}} {{translate('decking.system.umprod')}}</span>
                                                </p>
                                                <p class="text--sm fc--black mt-4" v-if="product.is_required == 0">
                                                    <span class="d-md-none">{{translate('decking.system.qtynec')}} c: </span>{{summary.support_count}} {{translate('decking.system.umprod')}}</span>
                                                </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <!-- accessori -->
                                <div v-for="(product, index) in system" class="row g-0">
                                    <div v-if="product.accessorios.length" v-for="accessorio in product.accessorios">
                                    <div class="col-lg-1 offset-lg-0 col-8 offset-2">
                                        <div class="td text-center text-md-start">
                                            <img v-if="accessorio.image" :src="accessorio.image" class="mb-1 w-100 w-sm-75 mx-auto h-auto px-xl-0 px-lg-2 px-4" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="td text-center text-md-start">
                                            <p class="text--sm fc--black">{{accessorio.commercial_code}}</p>
                                            <p class="text--md fc--black fw--bold">{{accessorio.name}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="td text-center text-md-start">
                                            <p class="text--sm fc--black"><span class="d-md-none">{{translate('decking.system.qtynec')}}: </span> {{translate('decking.system.umprod')}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td text-center text-md-start">
                                            <p class="text--sm fc--black"><span class="d-md-none">{{translate('decking.system.qtyord')}}: </span>{{translate('decking.system.'+accessorio.umv+'s')}}, (1 {{translate('decking.system.'+accessorio.umv)}}, {{accessorio.qtainumv}} {{translate('decking.system.umprod')}})</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-3 mt-lg-3 order-2 order-md-2">
                            <div class="row containerpacks">
                                <div class="col-lg-12 text-center">
                                    <p class="text--lg fc--black fw--bold">{{translate('decking.system.availablepacks')}}</p>
                                </div>
                                <div v-for="(pack, index) in packs" class="col-lg-4 g-0 p-2">
                                    <div class="row g-0">
                                        <div class="col-12 p-3" style="border:1px solid #000;">
                                            <div class="row g-0 mb-3">
                                                <div class="col-lg-2 col-3">
                                                    <img v-if="pack.image" :src="pack.image" class="mb-1 w-100 w-sm-100 mx-auto px-xl-0 px-lg-2" />
                                                </div>
                                                <div class="col-lg-10 col-9">
                                                    <p class="text--md fc--black mt-0 text-start"><b>{{pack.commercial_code}}</b><br>{{pack.name}}</p>
                                                    <p class="text--sm fc--black mb-2 text-end mt-2" v-if="pack.is_required == 1 && ((summary.width * summary.depth) / 1000000) <= 20">
                                                        <span class="d-md-none">{{translate('decking.system.qtyord')}}: </span><b>{{Math.ceil(summary.support_count / pack.qtainumv)}} {{translate('decking.system.'+pack.umv+'s')}}</b> (1 {{translate('decking.system.'+pack.umv)}} = {{pack.qtainumv}} {{translate('decking.system.umprod')}})</span>
                                                    </p>
                                                    <p class="text--sm fc--black mb-2 text-end mt-2" v-if="pack.is_required == 1 && ((summary.width * summary.depth) / 1000000) > 20">
                                                        <span class="d-md-none">{{translate('decking.system.qtyord')}}: </span><b>{{Math.ceil(((20 / ((tile_sizes[0].value*tile_sizes[1].value) / 1000000)) * (summary.support_count / summary.tile_count)) / pack.qtainumv)}} {{translate('decking.system.'+pack.umv+'s')}}</b> (1 {{translate('decking.system.'+pack.umv)}} = {{pack.qtainumv}} {{translate('decking.system.umprod')}})</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div v-for="(product, index) in system" v-if="(product.base > 1 && pack.kit == 0) || product.base > 2" class="row g-0 mb-3">
                                                <!-- prodotti vendibili -->
                                                <div v-if="product.base > 1 && product.base < 3 && pack.kit == 0" class="col-lg-2 col-3">
                                                    <img v-if="product.image" :src="product.image" class="mb-1 w-100 w-sm-100 mx-auto px-xl-0 px-lg-2" />
                                                </div>
                                                <div v-if="product.base > 1 && product.base < 3 && pack.kit == 0" class="col-lg-10 col-9">
                                                    <p class="text--md fc--black mt-0 text-start"><b>{{product.commercial_code}}</b><br>{{product.name}}</p>
                                                    <p class="text--sm fc--black mb-2 text-end mt-2" v-if="pack.is_required == 1 && product.base < 3 && ((summary.width * summary.depth) / 1000000) <= 20">
                                                        <span class="d-md-none">{{translate('decking.system.qtyord')}}: </span><b>{{Math.ceil(summary.support_count / product.qtainumv)}} {{translate('decking.system.'+product.umv+'s')}}</b> (1 {{translate('decking.system.'+product.umv)}} = {{product.qtainumv}} {{translate('decking.system.umprod')}})</span>
                                                    </p>
                                                    <p class="text--sm fc--black mb-2 text-end mt-2" v-if="pack.is_required == 1 && product.base < 3 && ((summary.width * summary.depth) / 1000000) > 20">
                                                        <span class="d-md-none">{{translate('decking.system.qtyord')}}: </span><b>{{Math.ceil(((20 / ((tile_sizes[0].value*tile_sizes[1].value) / 1000000)) * (summary.support_count / summary.tile_count)) / product.qtainumv)}} {{translate('decking.system.'+product.umv+'s')}}</b> (1 {{translate('decking.system.'+product.umv)}} = {{product.qtainumv}} {{translate('decking.system.umprod')}})</span>
                                                    </p>
                                                    <p class="text--sm fc--black mb-2 text-end mt-2" v-if="product.base == 3">
                                                        <b>1 {{translate('decking.system.umprod')}}</b></span>
                                                    </p>
                                                </div>

                                                <!-- pinza -->
                                                <div v-if="product.base > 2" class="col-lg-2 col-3">
                                                    <img v-if="product.image" :src="product.image" class="mb-1 w-100 w-sm-100 mx-auto px-xl-0 px-lg-2" />
                                                </div>
                                                <div v-if="product.base > 2" class="col-lg-10 col-9">
                                                    <p class="text--md fc--black mt-0 text-start"><b>{{product.commercial_code}}</b><br>{{product.name}}</p>
                                                    <p class="text--sm fc--black mb-2 text-end mt-2" v-if="pack.is_required == 1 && product.base < 3 && ((summary.width * summary.depth) / 1000000) <= 20">
                                                        <span class="d-md-none">{{translate('decking.system.qtyord')}}: </span><b>{{Math.ceil(summary.support_count / pack.qtainumv)}} {{translate('decking.system.'+pack.umv+'s')}}</b> (1 {{translate('decking.system.'+pack.umv)}} = {{pack.qtainumv}} {{translate('decking.system.umprod')}})</span>
                                                    </p>
                                                    <p class="text--sm fc--black mb-2 text-end mt-2" v-if="pack.is_required == 1 && product.base < 3 && ((summary.width * summary.depth) / 1000000) > 20">
                                                        <span class="d-md-none">{{translate('decking.system.qtyord')}}: </span><b>{{Math.ceil(((20 / ((tile_sizes[0].value*tile_sizes[1].value) / 1000000)) * (summary.support_count / summary.tile_count)) / pack.qtainumv)}} {{translate('decking.system.'+pack.umv+'s')}}</b> (1 {{translate('decking.system.'+pack.umv)}} = {{pack.qtainumv}} {{translate('decking.system.umprod')}})</span>
                                                    </p>
                                                    <p class="text--sm fc--black mb-2 text-end mt-2" v-if="product.base == 3">
                                                        <b>1 {{translate('decking.system.umprod')}}</b></span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios'
    import {mapState, mapGetters} from 'vuex'
    export default {
        name: 'System',
        beforeMount: function() {
            this.$eventBus.$emit('SET-LOADER', true)
        },
        mounted: function(){
            axios.post('/api/v1/floor/render',this.getPayload).then(response => {
                if (response.status == 200) {
                    this.$store.commit('updateState', {
                        key: 'summary',
                        value: response.data
                    });
                    axios.post('/api/v1/floor/articles',{
                        tile_height:this.tile_sizes[3].value,
                        product_id:this.active_product.id,
                        fuga:this.tile_sizes[2].value
                    }).then(response => {
                        if (response.status == 200) {
                            this.$store.commit('updateState', {
                                key: 'system',
                                value: response.data.products
                            });
                            this.$store.commit('updateState', {
                                key: 'packs',
                                value: response.data.packs
                            });
                            // console.dir()
                            this.$eventBus.$emit('SET-LOADER',false);
                        }
                    })
                }
            }).catch(e => {
                this.$eventBus.$emit('SET-LOADER',false);
                // 'decking.base.continua'
                this.$eventBus.$emit('ALERT-ERROR','decking.base.calcolerror');
            });
        },
        computed: {
            ...mapGetters([
                'getPayload',
                'getCurrentLang'
            ]),
            ...mapState({
                tile_sizes: state => state.tile_sizes,
                area_sizes: state => state.area_sizes,
                active_product: state => state.active_product,
                active_orientation: state => state.active_orientation,
                active_laying: state => state.active_laying,
                summary: state => state.summary,
                system: state => state.system,
                packs: state => state.packs
            })
        },
        methods: {
            prevStep(){
                return 'laying'
            },
            nextStep(){
                return 'pdf'
            }
        }

    }
</script>
